<template>
    <div>
        <template v-if="$route.params.siteId && $route.params.userId && $route.params.siteName">
            <router-view></router-view>
        </template>
        <v-col v-else class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 pb-0">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Harmonys</h1>
                    <p class="desc mt-0 mb-1">Listado de harmonys creados.</p>
                </v-alert>
            </v-col>

            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field
                        v-model="search"
                        @input="fetchHarmonys"
                        color="#8061c2"
                        label="Buscar"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        outlined
                        class="mb-2 mx-0 px-0"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="px-md-2 px-0">
                    <v-select
                        v-model="filter"
                        :items="filterOptions"
                        label="Filtrar por"
                        outlined
                        hide-details
                    ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="px-0 ">
                    <v-select
                        v-model="order"
                        :items="orderOptions"
                        label="Ordenar"
                        outlined
                        hide-details
                    ></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table
                :headers="headers"
                :items="harmonys"
                :loading="loading"
                class="elevation-1 mx-3 px-2 py-4"
                :items-per-page="perPage"
                :server-items-length="totalSites"
                :page.sync="page"
                :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, 300, -1], 
                    showFirstLastPage: true
                }"
                @update:page="fetchHarmonys"
                @update:items-per-page="changePerPage"
            >
                <!-- Columnas y Templates -->
                <template v-slot:item.logo_path="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                                size="35"
                                class="clickable-avatar"
                                @click="showLogo(item.logo_path)"
                                @mouseover="hoveredLogo = item.logo_path"
                                @mouseleave="hoveredLogo = null"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                                <v-img :src="require('../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                            </v-avatar>
                        </template>
                        <span>Ver Imagen</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.visits="{ item }">
                    {{ item.abbreviated_visits || 0 }}
                </template>


                <template v-slot:item.creator_type="{ item }">
                    <v-chip :color="item.creator_type && item.creator_type.id ? getCreatorColor(item.creator_type.id) : 'grey'" dark small>
                        {{ item.creator_type && item.creator_type.id ? item.creator_type.name : 'N/A' }}
                    </v-chip>
                </template>


                <template v-slot:item.status="{ item }">
                    <v-chip :color="getStatusColor(item.status.id)" dark small>
                        {{ item.status.name }}
                    </v-chip>
                </template>

                <template v-slot:item.user.email="{ item }">
                    {{ item.user ? item.user.email : 'Sin correo electrónico' }}
                </template>

                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                </template>

                <template v-slot:item.media="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                small
                                color="rgb(190 192 193)"
                                dark
                                class="mr-1"
                                v-if="item.total_videos > 0"
                                v-bind="attrs"
                                v-on="on"
                                label
                            >
                                🎥 {{ item.total_videos }}
                            </v-chip>
                        </template>
                        <span>Videos</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                small
                                color="rgb(190 192 193)"
                                dark
                                class="mr-1"
                                v-if="item.total_backgrounds > 0"
                                v-bind="attrs"
                                v-on="on"
                                label
                            >
                                🎴 {{ item.total_backgrounds }}
                            </v-chip>
                        </template>
                        <span>Backgrounds</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                small
                                color="rgb(190 192 193)"
                                dark
                                class="mr-1"
                                v-if="item.total_galleries > 0"
                                v-bind="attrs"
                                v-on="on"
                                label
                            >
                                📸 {{ item.total_galleries }}
                            </v-chip>
                        </template>
                        <span>Galerías</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                small
                                color="rgb(190 192 193)"
                                dark
                                class="mr-1"
                                v-if="item.total_socials > 0"
                                v-bind="attrs"
                                v-on="on"
                                label
                            >
                                📱 {{ item.total_socials }}
                            </v-chip>
                        </template>
                        <span>Redes Sociales</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.weight="{ item }">
                    {{ item.total_weight == 'NAN B' ? '0 B' : item.total_weight }}
                </template>

                <template v-slot:item.created_at="{ item }">
                    {{ item.created_at_formatted }}
                </template>

                <!-- Botones de acciones -->
                <template v-slot:item.actions="{ item }">
                    <!-- Botón para estadísticas -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="goToStatistics(item)">
                                <v-icon>mdi-chart-line</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver Estadísticas</span>
                    </v-tooltip>

                    <!-- Nuevo botón para ir al Harmony -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.slug)">
                                <v-icon>mdi-web</v-icon>
                            </v-btn>
                        </template>
                        <span>Ir al Harmony</span>
                    </v-tooltip>

                    <!-- Botón para impersonar usuario con tooltip -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.user_id)">
                                <v-icon>mdi-account-switch</v-icon>
                            </v-btn>
                        </template>
                        <span>Impersonar Usuario</span>
                    </v-tooltip>

                   <!-- Botón para ver los links del harmony con tooltip -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(223 165 27)" v-bind="attrs" v-on="on" @click="goToLinks(item.slug)">
                                <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver Enlaces</span>
                    </v-tooltip>

                    <!-- Botón para eliminar -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="#eb7269" v-bind="attrs" v-on="on" @click="confirmDelete(item.id, item.user_id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar Harmony</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openEditDialog(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar Harmony</span>
                </v-tooltip>
                </template>

            </v-data-table>

            <!-- Modal para ampliar el logo -->
            <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
                <v-img :src="selectedLogo" width="14rem"></v-img>
            </v-dialog>

            <v-dialog transition="dialog-top-transition" max-width="600" v-model="editDialog">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formEditUser" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0" class="col-12 px-10 py-10 pb-4 mb-0">

                            <div class="text-left">
                                <v-row class="mb-0">
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">Actualizar información</h2>
                                </v-row>
                                <p class="mb-8 desc">Los datos del harmony <strong>{{ editedSite.name }}</strong>, serán actualizados.</p>
                            </div>
                            <div class="text-left mb-8">
                                <v-row>
                                    <div class="col-12 col-md-6 p-0 m-0">
                                        <v-select v-model="editedSite.gender_id" hide-details :items="genders" item-text="name" item-value="id" outlined label="Género" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                    <div class="col-12 col-md-6 p-0 m-0">
                                        <v-select v-model="editedSite.age_id" hide-details :items="ages" item-text="name" item-value="id" outlined label="Edad" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                </v-row>

                                <v-row>
                                    <div class="col-12 col-md-12 p-0 m-0">
                                        <v-select v-model="editedSite.creator_type_id" hide-details :items="creator_types" item-text="name" item-value="id" outlined label="Tipo" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                </v-row>
                            </div>
                            <div class="text-right">
                                <v-btn :disabled="saveLoading" @click="updateUser" :loading="saveLoading" color="#8e84c0" class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                    <v-icon left color="white">mdi-account-check-outline</v-icon>
                                    Actualizar
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        </v-col>
    </div>
</template>

<script>
import axios from '@/axios.js';
import impersonationMixin from '@/mixins/impersonationMixin';

export default {
    name: "Harmonys",
    mixins: [impersonationMixin],
    data() {
        return {
            harmonys: [],
            search: '',  // Término de búsqueda
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalSites: 0,
            order: 'desc',  // Orden por defecto
            filter: 'created_at',  // Filtro por defecto
            headers: [
                { text: 'Logo', value: 'logo_path', align: 'start', sortable: false },
                { text: 'Nombre', value: 'name', align: 'start' },
                { text: 'Visitas', value: 'visits', sortable: true },
                { text: 'Creador', value: 'user.email' },
                { text: 'Media', value: 'media', sortable: false },  // Nueva columna para Videos, Backgrounds, Galleries, y Socials
                { text: 'Tipo', value: 'creator_type', sortable: false },
                { text: 'Estado', value: 'status' },
                { text: 'Fecha de Creación', value: 'created_at' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            orderOptions: [
                { text: 'Mayor', value: 'desc' },
                { text: 'Menor', value: 'asc' }
            ],
            filterOptions: [
                { text: 'Fecha Creación', value: 'created_at' },
                { text: 'Vistas', value: 'views' }
            ],
            saveLoading: false,
            editedSite: {},
            genders: [],
            ages: [],
            creator_types: [],
            editDialog: false,
        };
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchHarmonys();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchHarmonys();
            }
        }
    },

    created() {
        this.initializeSearch();
        this.fetchHarmonys();
        this.getGenders();
        this.getAges();
        this.getCreatorTypes();
        this.$i18n.locale = 'es';
    },
    methods: {
        getCreatorColor(creator_id) {
            if (creator_id === 1) {
                return '#ed6f33';
            } else if (creator_id === 2) {
                return '#62a1e1';
            } else if (creator_id === 3) {
                return '#e162ba';
            } else if (creator_id === 4) {
                return '#00936a';
            }
            return 'grey';
        },
        openEditDialog(site) {
            this.editedSite = {
                ...site
            };
            this.editDialog = true;
        },

        async updateUser() {
            this.saveLoading = true;
            try {
                await axios.post('/admin/update-creator', {
                    ...this.editedSite,
                    site_id: this.editedSite.id
                });

                this.$vs.notify({
                    title: 'Actualización',
                    text: 'Se han guardado los datos correctamente',
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                this.editDialog = false;
                this.fetchHarmonys();
            } catch (error) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'No se ha podido actualizar los datos',
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error(error);
            } finally {
                this.saveLoading = false;
            }
        },
        initializeSearch() {
            const searchQuery = this.$route.query.search;
            if (searchQuery) {
                this.search = searchQuery;
            }
        },

        async fetchHarmonys() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-sites', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        order: this.order,
                        filter: this.filter
                    }
                });

                this.harmonys = response.data.sites.data;
                this.totalSites = response.data.sites.total;

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchHarmonys();
        },

        goToStatistics(item) {
            const harmonyUrl = `${window.location.origin}/panel/admin/stats?id=${item.id}&user_id=${item.user.id}&name=${encodeURIComponent(item.name)}`;
            window.open(harmonyUrl, '_blank');
        },

        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        getStatusColor(statusId) {
            switch (statusId) {
                case 1: return 'orange';
                case 2: return 'gray';
                case 3: return 'rgb(101 201 105)';
                case 4: return '#ff756a';
                default: return 'blue';
            }
        },

        confirmDelete(siteId, userId) {
            this.$swal.fire({
                title: this.$t('panelHome.confirmDelete-title'),
                text: this.$t('panelHome.confirmDelete-text'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'rgb(112 201 109)',
                cancelButtonColor: '#eb7c7c',
                confirmButtonText: this.$t('panelHome.confirmDelete-ButtonText'),
                cancelButtonText: this.$t('panelHome.confirmDelete-cancelButtonText'),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteHarmony(siteId, userId);
                }
            });
        },

        async deleteHarmony(siteId, userId) {
            try {
                await axios.post('/set-site-status', { site_id: siteId, status_id: 4, user_id: userId });
                this.$vs.notify({
                    title: this.$t('panelHome.delete-title'),
                    text: this.$t('panelHome.delete-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });
                this.fetchHarmonys();
            } catch (error) {
                console.log(error.response);
            }
        },

        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },

        goToLinks(slug) {
            const routeData = this.$router.resolve({
                name: 'Links',
                query: { search: slug }
            });

            window.open(routeData.href, '_blank');
        },

        async getGenders() {
            try {
                const response = await axios.get('/admin/get-genders');
                this.genders = response.data.genders;
            } catch (error) {
                console.error(error);
            }
        },
        async getAges() {
            try {
                const response = await axios.get('/admin/get-ages');
                this.ages = response.data.ages;
            } catch (error) {
                console.error(error);
            }
        },
        async getCreatorTypes() {
            try {
                const response = await axios.get('/admin/get-creator-types');
                this.creator_types = response.data.creatorTypes;
            } catch (error) {
                console.error(error);
            }
        },
    }
};
</script>

<style scoped>
.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}
</style>
